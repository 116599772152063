<template>
  <!-- 登录模块 -->
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <img src="@/assets/logo.png" alt="登录头部图">
      <div class="title-container">
        <h3 class="title">青训小将综合管理平台</h3>
      </div>
      <div class="select-box">
        <div v-for="item in select" @click="changeActive(item)" :key='item.value' :class="[item.value === active ? 'active' : '','select']">{{ item.name }}</div>
      </div>
      <el-form-item prop="Phone">
        <el-input ref="Phone" v-model="loginForm.Phone" placeholder="账号" name="Phone" type="text" tabindex="1" auto-complete="on" />
      </el-form-item>
      <el-form-item prop="Password" v-if="active === 1">
        <el-input :key="passwordType" ref="Password" v-model="loginForm.Password" :type="passwordType" placeholder="密码" name="Password" tabindex="2" show-password auto-complete="on" @keyup.enter.native="handleLogin" />
      </el-form-item>
      <el-form-item prop="VerifyCode" v-else>
        <el-row>
          <el-col :span='16'>
            <el-input v-model="loginForm.VerifyCode" placeholder="验证码" name="VerifyCode" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
          </el-col>
          <el-col :span='6' :offset="2">
            <el-button v-if="status !== 2" class='btn-box' type="primary" @click.native.prevent="getTencentCaptcha">{{ status === 1 ?  '获取验证码' : '重新获取' }}</el-button>
            <el-button v-else class='btn-box'>{{ time }}秒</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-button :loading="loading" type="primary" class="login-button" @click.native.prevent="handleLogin">登录</el-button>
      <div class="footer" @click="openLogin">
        <img src="@/assets/WeChat.png" alt="微信扫码" class="WeChat-img">
        <p class="WeChat-p">微信</p>
      </div>
    </el-form>
    <div class="footer-box "><span class="pr-4">Copyright 2014 - 2022 趣点科技版权所有. 备案号: </span>
      <a a href="https://beian.miit.gov.cn/" data-v-749f2458=""><span data-v-749f2458="">&nbsp;蜀ICP备20008447号&nbsp;</span></a>
    </div>
    <el-dialog title="扫码登录" :close-on-click-modal='false' :visible.sync="loginVisible" custom-class='login-box'>
      <div class="login_container" id='login_container'>
      </div>
    </el-dialog>
    <el-dialog title="切换俱乐部" :visible.sync="OrgNameVisible" width="30%" append-to-body :close-on-click-modal='false'>
      <el-select class=" statusBox" v-model="loginForm.OrgId" clearable placeholder="请选择">
        <el-option v-for="item in OrgNameList" :key="item.Id" :label="item.Name" :value="item.Id">
        </el-option>
      </el-select>
      <div class="saveRevise">
        <el-button type="primary" @click="updateClub()">进入</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken, elderLogin, sendClubTeacherPhoneBound, teacherLogin, getCaptcha } from '@/service/auth.js';
import { saveSessionStorage, loadSessionStorage } from '@/utils/cache.js';
import md5 from 'js-md5';

export default {
  name: "Login",
  data() {

    /**
     * 手机校验规则
     */
    const validatePhone = (rule, value, callback) => {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value || value.trim() === '') {
        callback(new Error('请输入手机号'));
      } else if (!reg_tel.test(value.trim())) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };

    /**
     * 密码校验规则
     */
    const validatePassword = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };

    /**
     * 验证码码校验规则
     */
    const validateVerifyCode = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        Phone: '',
        Password: '',
        VerifyCode: ''
      },
      loginRules: {
        Phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        Password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        VerifyCode: [{ required: true, trigger: 'blur', validator: validateVerifyCode }],
      },
      loginVisible: false,
      loading: false,
      passwordType: 'password',
      active: 1,  // 登录方式状态
      status: 1, // 验证码状态
      time: 60, // 时间
      select: [
        {
          value: 1,
          name: '账号密码登录'
        },
        {
          value: 2,
          name: '手机号登录'
        }
      ],
      OrgNameVisible: false,
      OrgNameList: [],
    }
  },

  async created() {
    if (!loadSessionStorage('Token')) {
      let res = await getToken({ Signature: process.env.VUE_APP_SIGNATURE });
      saveSessionStorage('Token', res.Token);
    }
    if (!this.$route.query.code) return; // 回调没得code
    let ret = await elderLogin({ JSCode: this.$route.query.code });
    // if (ret.Code === '1100') {
    //   this.OrgNameVisible = true;
    //   this.updateClub();
    // };
    if (ret.Code === '1107') {
      this.$router.push({ path: '/bindPhone' });
    } else {
      this.$store.commit('set_userInfo', ret);
      this.$router.push({ path: '/home' });
    }
  },

  methods: {

    async updateClub() {
      // let res = await this.$confirm('确定进入该俱乐部吗？', '提示', {
      //   distinguishCancelAndClose: true,
      //   confirmButtonText: '确 定',
      //   cancelButtonText: '取 消',
      //   type: 'warning',
      //   center: true,
      // });
      // if (res !== 'confirm') return;
      // let ret = await teacherChangeOrg({ OrgId: this.userInfo.OrgId });
      // this.$store.commit('set_userInfo', ret);
      // this.$message({
      //   message: '进入成功',
      //   type: 'success',
      //   center: true,
      // });
      this.OrgNameVisible = false;
    },

    /**
     * 点击登录
     */
    handleLogin() {
      // 校验用户名和密码
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return;
        let res = await teacherLogin({
          Phone: this.loginForm.Phone,
          Password: this.active === 1 ? md5(this.loginForm.Password) : undefined,
          VerifyCode: this.active === 2 ? this.loginForm.VerifyCode : undefined,
        });
        this.$store.commit('set_userInfo', res);
        this.$router.push({ path: '/home' });
      });
    },

    /**
     * 改变状态
    */
    changeActive(item) {
      if (item.value === this.active) return;
      this.active = item.value;
    },

    /**
     * 渲染微信二维码
    */
    openLogin() {
      this.loginVisible = true;
      this.$nextTick(() => {
        new WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: process.env.VUE_APP_APPID,
          scope: "snsapi_login",
          redirect_uri: encodeURIComponent(window.location.origin),
          state: "",
          style: "",
          href: ""
        });
      });
    },

    /**
     * 图形验证码
     */
    getTencentCaptcha() {
      this.$refs.loginForm.validateField('Phone', (valid) => {
        if (valid) {
          return;
        }
        const captchaId = '2081383555'; // 腾讯滑块验证码appid
        // 生成一个滑块验证码对象
        let captcha = new TencentCaptcha(captchaId,
          async (res) => {
            // 后台校验方法
            if (res.ret === 0) {
              // await getCaptcha({
              //   Ticket: res.ticket,
              //   Randstr: res.randstr
              // });
              this.getPassword(res);
            }
          })
        // 滑块显示
        captcha.show();
      })
    },

    /**
     * 获取验证码
     */
    async getPassword(res) {
      let ret = await sendClubTeacherPhoneBound({
        Phone: this.loginForm.Phone,
        Ticket: res.ticket,
        Randstr: res.randstr
      });
      if (ret.Code === '1107') {
        this.$message.error(ret.Msg);
      } else {
        this.status = 2;
        let timer = setInterval(() => {
          this.time -= 1;
          if (this.time === 0) {
            this.status = 3;
            this.time = 60; // 重置时间
            clearInterval(timer);
          }
        }, 1000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #fff;
$dark_gray: #889aa4;
$light_gray: #0079fe;

.login-container {
  // min-height: 100%;
  height: 100%;
  width: 100%;
  background-color: rgba(#8fcc80, 0.2);
  // overflow: hidden;
  overflow-y: auto;
  padding: 50px 0;
  .login-form {
    position: relative;
    width: 400px;
    height: 510px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: $bg;
    border-radius: 6px;
    padding: 0 20px 100px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #21293a;
      margin: 0px auto 10px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  /deep/.el-input .el-input__inner {
    height: 38px !important;
  }
  .login-button {
    width: 100%;
    margin: 5px 0 30px;
    font-size: 20px;
    height: 38px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .WeChat-img {
    width: 40px;
    height: 40px;
    margin: 0 0 8px;
    cursor: pointer;
  }
  .WeChat-p {
    font-size: 16px;
    color: #333333;
    cursor: pointer;
  }
}
p {
  font-size: 16px;
  color: #5e5e5e;
  text-align: center;
}
// 设置input的placeholder字体颜色
/deep/input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #000;
}
/deep/input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #000;
}
/deep/input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #000;
}
/deep/input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #000;
}

.select-box {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  .select {
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
  }
  .active {
    color: #0079fe;
    border-bottom: 2px solid #0079fe;
  }
}

img {
  display: block;
  width: 150px;
  margin: 20px auto;
}

.btn-box {
  width: 100%;
  height: 38px;
}
.footer-box {
  // background-color: rgba(#8fcc80, 0.2);
  border-top: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  position: fixed;
  // position: absolute;
  bottom: 0;
  color: #5c6273;
  font-size: 14px;
  a {
    text-decoration: none;
    color: #5c6273;
  }
}
</style>
